import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Logo from '../../assets/images/logo.svg'

const Header = () => {
  return (
    <Nav>
      <Link to="/">
        <Image
          src={Logo}
          alt="Raven logo"
        />
      </Link>
    </Nav>
  );
}

export default Header

const Image = styled.img`
	width: 100px;
	cursor:	pointer;
`

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  padding: 15px;
  background-color: #072240;
`;