import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { ThemeProvider } from 'styled-components';
import theme  from './Style';

import { JWTProvider } from "./contexts/JWTContext";

import { ToastProvider } from "react-toast-notifications";
import ScrollToTop from "./components/ScrollTop/ScrollTop";
import routes, { renderRoutes } from "./routes";
import { BASENAME } from "./config/constant";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
registerLocale('es', es)
setDefaultLocale('es', es)

const App = () => {
  return (
    <React.Fragment>
      <Router basename={BASENAME}>
      <ScrollToTop />
        <ThemeProvider theme={theme}>
            <JWTProvider>
              <ToastProvider autoDismiss={true}>
                {renderRoutes(routes)}
              </ToastProvider>
            </JWTProvider>
        </ThemeProvider>
        </Router>
    </React.Fragment>
  );
};

export default App;
