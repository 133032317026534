import React from 'react'
import styled from 'styled-components'
import Logo from '../../assets/images/logo.svg'
import { Col, Row } from 'react-bootstrap';

const Footer = () => {
  return (
    <Container>
      <ContainerTop>
        <div>
          <article>
            <figure>
              <Image src={Logo} alt="logo" />
            </figure>
            <Text>
              GENERANDO UN IMPACTO DURADERO EN LA SALUD HUMANA POR MÁS DE 130
              AÑOS
            </Text>
          </article>
        </div>
        <div>
          <Text>INFORMACIÓN LEGAL</Text>
        </div>
      </ContainerTop>
      <div>
      <div>
        <Row>
          <Col className="mt-3" xl={12}>
            <Title>FUNCIONAMIENTO</Title>
          </Col>
        </Row>
        <Row>
          <Col xl={12}>
            <Title>
              ¿Cómo veo la información sobre mi condición o padecimiento?
            </Title>
            <TextParagraph>
              A partir de tu registro hemos creado un casillero personalizado para que mensualmente recibás información de tu interés. (Acceder)
            </TextParagraph>
          </Col>
        </Row>
        <Row>
          <Col xl={12}>
            <Title>¿Cómo canjear mis productos?</Title>
            <TextParagraph>
              1. Registra tus compras, ver lista de medicamentos participantes en el reglamento publicado en esta página.
            </TextParagraph>
            <TextParagraph>
              2. Por tus reportes acumulados te daremos un código de canje. Los medicamentos deben ser previamente recetados por tu médico.
            </TextParagraph>
            <TextParagraph>
              3. Presentar tu código de canje en tu farmacia de preferencia para recibir tus medicamentos.
            </TextParagraph>
          </Col>
        </Row>
        <Row>
          <Col xl={12}>
            <Title>¿Cómo registro mi compra?</Title>
            <TextParagraph>
              1. Compra tus medicamentos de Laboratorio Raven participante del programa.
            </TextParagraph>
            <TextParagraph>
              2. Ingresá a tu cuenta, en el menú izquierdo presioná "Mis compras", después, presioná la opción "Registro de compras" y llená los datos de tu compra.
            </TextParagraph>
            <TextParagraph>
              3. Adjuntá la fotografía de tu factura. La factura debe ser original, 
              no se aceptan facturas manuales, fotocopias, facturas poco legibles o repetidas. 
              La factura debe contener: número de factura, fecha, producto de compra, precio del producto 
              y cantidad de compra. Todos los datos deben ser legibles para poder validar la compra. 
            </TextParagraph>
            <TextParagraph>
              4. Cuando termines puedes ir a "Estado de Cuenta" para verificar tus posibilidades de canje.
            </TextParagraph>
          </Col>
        </Row>
        <Row>
          <Col xl={12}>
            <Title>¿Cómo puedo conseguir mi código de canje?</Title>
            <TextParagraph>
              1. Accedé a tu cuenta y en el menú izquierdo presioná "Mis Canjes", después,  presioná la opción "Canje de productos" y llená los datos del formulario.
            </TextParagraph>
            <TextParagraph>
              2. Presiona canjear producto y recibe tu código de canje.
            </TextParagraph>
          </Col>
        </Row>
        <Row>
          <Col xl={12}>
            <Reminder>
              *Recuerda que para canjear productos debes cumplir con la cantidad 
              de compras registradas necesarias y el canje del medicamento es el 
              mismo que se ha estado comprando. Todo medicamento debe ser recetado 
              por tu médico tratante. 
            </Reminder>
          </Col>
        </Row>
      </div>
      </div>
    </Container>
  );
}

export default Footer

const Image = styled.img`
	width: 100px;
	cursor:	pointer;
`

const Container = styled.div`
  background-color: #072240;
  padding: 20px;
  @media (min-width: 990px) {
    padding: 50px;
  }
`;
const ContainerTop = styled.div`  
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 80% 25%;
  padding-bottom: 50px;
  @media (min-width: 990px) {
    grid-template-columns: 80% 20%;
    grid-template-rows: 90%;
  }
`;

const Text = styled.p`
  color: #fff;
  font-size: 15px;
  font-weight: 600;
`

const Title = styled.h6`
	font-size: 18px;
	font-weight: 600;
  color: ${props => props.theme.colors.white};
`
const TextParagraph = styled.p`
  font-size: 13px;
  line-height: 20px;
  color: #fff;
`;

const Reminder = styled.p`
	font-size: 13px;
  color: ${props => props.theme.colors.white};
`;