const menuItemsPharmacy = {
  items: [
    {
      id: 'navigation',
      type: 'group',
      children: [
        {
          id: 'inicio',
          title: 'INICIO',
          type: 'item',
          url: '/app/dashboard/default',
        },
        {
          id: 'doctors',
          title: 'DOCTORES',
          type: 'item',
          url: '/app/dashboard/doctors',
        },
        {
          id: 'pharmacies',
          title: 'FARMACIAS',
          type: 'item',
          url: '/app/dashboard/pharmacies',
        },
        {
          id: 'medicamentos',
          title: 'MEDICAMENTOS',
          type: 'collapse',
          children: [
            {
              id: 'drugsCategories',
              title: 'CATEGORIAS',
              type: 'item',
              url: '/app/dashboard/drugs/categories',
            },
            {
              id: 'drugs',
              title: 'MEDICAMENTOS',
              type: 'item',
              url: '/app/dashboard/drugs',
            }
          ],
        },     
        {
          id: 'invoices',
          title: 'FACTURAS',
          type: 'item',
          url: '/app/dashboard/invoices',
        },   
        {
          id: 'restock',
          title: 'REPOSICIONES',
          type: 'item',
          url: '/app/dashboard/restock',
        },
        {
          id: 'employees',
          title: 'USUARIOS',
          type: 'item',
          url: '/app/dashboard/employees',
        },
        {
          id: 'patients',
          title: 'PACIENTES',
          type: 'item',
          url: '/app/dashboard/patients',
        }
      ],
    },
  ],
};

export default menuItemsPharmacy;
