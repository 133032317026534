import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import LogoRaven from '../../../assets/images/logo.svg';
import User from '../User';
import styled from 'styled-components';

import { ConfigContext } from "../../../contexts/ConfigContext";
import * as actionType from "../../../store/actions";
import MenuIcon from '../../../assets/images/icons/icon-menu.png';

const NavBar = () => {
    const configContext = useContext(ConfigContext);
    const { collapseMenu, headerBackColor, headerFixedLayout, layout, subLayout } = configContext.state;
    const { dispatch } = configContext;

    let headerClass = ['navbar', 'pcoded-header', 'navbar-expand-lg', headerBackColor];
    if (headerFixedLayout && layout === 'vertical') {
        headerClass = [...headerClass, 'headerpos-fixed'];
    }

    let toggleClass = ['mobile-menu'];
    if (collapseMenu) {
      toggleClass = [...toggleClass, 'on'];
    }

    const navToggleHandler = () => {
        dispatch({type: actionType.COLLAPSE_MENU})
    };

    let navBar = (
      <React.Fragment>
        <div className="m-header">
          
          <Link to="#" className={toggleClass.join(' ')} id="mobile-collapse" onClick={navToggleHandler} >
            <Image src={MenuIcon} alt="Menu" style={{width: "100%", filter: "invert(1)"}} />
            {/** <span />  */}
          </Link>
          
          <Link to="#" className="b-brand">
            <div>
              <Image src={LogoRaven} alt="Logo Raven" />
            </div>
          </Link>
          {/* <Link to='#' className={moreClass.join(' ')} onClick={() => setMoreToggle(!moreToggle)}>
                    <i className="feather icon-more-vertical"/>
                </Link> */}
        </div>
       
      </React.Fragment>
    );

    if (layout === 'horizontal' && subLayout === 'horizontal-2') {
        navBar = (
            <div className="container">
            {navBar}
            </div>
        );
    }

    return (
      <React.Fragment>
        <ContainerBorderBottom>
          <header className={headerClass.join(' ')}>
            {navBar}
            <User />
          </header>
        </ContainerBorderBottom>
      </React.Fragment>
    );
};

export default NavBar;

const ContainerBorderBottom = styled.div`
  height: 42px;
  & header {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start
  }
  
  @media (min-width: 992px) {
    border-bottom: 1px solid ${(props) => props.theme.colors.borderBottomColor};
  }
`;

const Image = styled.img`
    width: 130px;
`