const menuItemsPharmacy = {
  items: [
    {
      id: 'navigation',
      type: 'group',
      children: [
        {
          id: 'redemptions',
          title: 'REDENCIONES',
          type: 'item',
          url: '/app/dashboard/redemptions/patient'
        }, {
          id: 'restock',
          title: 'REPOSICIONES',
          type: 'item',
          url: '/app/dashboard/restock',
        },
      ],
    },
  ],
};

export default menuItemsPharmacy;
