import React from 'react'
import styled from 'styled-components'
import useAuth from '../../../hooks/useAuth';

const Index = () => {

  const { user } = useAuth();

  return (
    <ContainerUser>
      <p>{user.name}</p>
    </ContainerUser>
  );
}

export default Index

const ContainerUser = styled.div`
  display: none;
  width: auto;
  height: 42px;
  padding: 0 20px;
  background: ${(props) => props.theme.colors.purple};
  & p {
    white-space: nowrap;
    margin-bottom: 0;
    text-transform: uppercase;
    font-weight: 700;
    color: ${(props) => props.theme.colors.white};
  }

  @media (min-width: 480px) {
    display: flex;
    align-items: center;
	  margin: auto 0;
    margin-right: 75px;
  }
  
  @media (min-width: 992px) {
	margin: 0;
  }
`;