// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav--logout {
  padding: 7px 14px;
  color: #a9b7d0;
  font-size: 14px;
  margin-bottom: 0;
  cursor: pointer; }
  .nav--logout:hover {
    color: #1dc4e9; }
`, "",{"version":3,"sources":["webpack://./src/layouts/AdminLayout/Navigation/NavContent/NavContent.scss"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,eAAe,EAAA;EALjB;IAOI,cAAc,EAAA","sourcesContent":[".nav--logout {\n  padding: 7px 14px;\n  color: #a9b7d0;\n  font-size: 14px;\n  margin-bottom: 0;\n  cursor: pointer;\n  &:hover {\n    color: #1dc4e9;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
