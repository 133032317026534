import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { QueryClient, QueryClientProvider } from 'react-query';
import React from 'react';
import ReactDOM from 'react-dom';

import './services';

import { Provider } from 'react-redux';
import { ConfigProvider } from "./contexts/ConfigContext";

import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from "./store";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    },
  },
});

ReactDOM.render(
    <Provider store={store}>
        <QueryClientProvider client={queryClient}>
            <ConfigProvider>
                <App />
            </ConfigProvider>
        </QueryClientProvider>
    </Provider>,
  document.getElementById('root')
);

reportWebVitals();
