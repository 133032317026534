import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Route } from 'react-router-dom';

import Loader from './components/Loader/Loader';
import AdminLayout from './layouts/AdminLayout';
import HomeLayout from './layouts/HomeLayout';

import GuestGuard from './components/Auth/GuestGuard';
import AuthGuard from './components/Auth/AuthGuard';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<Loader />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
)

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('./views/errors/NotFound404')),
  },
  {
    exact: true,
    path: '/maintenance/coming-soon',
    component: lazy(() => import('./views/maintenance/ComingSoon')),
  },
  {
    exact: true,
    path: '/maintenance/error',
    component: lazy(() => import('./views/maintenance/Error')),
  },
  {
    exact: true,
    path: '/maintenance/offline-ui',
    component: lazy(() => import('./views/maintenance/OfflineUI')),
  },
  {
    path: '/app/',
    layout: AdminLayout,
    guard: AuthGuard,
    routes: [
      {
        exact: true,
        path: '/app/dashboard/default',
        component: lazy(() => import('./views/dashboard/DashDefault')),
      },
      {
        exact: true,
        path: '/app/dashboard/profile',
        component: lazy(() => import('./views/dashboard/DashProfile')),
      },
      {
        exact: true,
        path: '/app/dashboard/estado-de-cuenta',
        component: lazy(() => import('./views/dashboard/DashStatusAccount')),
      },
      {
        exact: true,
        path: '/app/dashboard/mi-cajita',
        component: lazy(() => import('./views/dashboard/DashBox')),
      },
      {
        exact: true,
        path: '/app/dashboard/editar-perfil',
        component: lazy(() => import('./views/dashboard/DashEditProfile')),
      },
      {
        exact: true,
        path: '/app/registrar-compras',
        component: lazy(() => import('./views/page-layouts/registerPurchases')),
      },
      {
        exact: true,
        path: '/app/invoice-rejected',
        component: lazy(() => import('./views/page-layouts/invoiceRejected')),
      },
      {
        exact: true,
        path: '/app/user-experience',
        component: lazy(() => import('./views/page-layouts/userExperience')),
      },
      {
        exact: true,
        path: '/app/canjear-mis-puntos',
        component: lazy(() => import('./views/page-layouts/redeemPoints')),
      },
      {
        exact: true,
        path: '/app/canjes-pendientes',
        component: lazy(() => import('./views/page-layouts/pendingPoints')),
      },
      {
        exact: true,
        path: '/app/puntos-medicamente-producto',
        component: lazy(() =>
          import('./views/page-layouts/pointsTreatmentOrProduct')
        ),
      },
      {
        exact: true,
        path: '/app/e-ticket',
        component: lazy(() => import('./views/page-layouts/eTickets')),
      },
      {
        exact: true,
        path: '/app/e-labs',
        component: lazy(() => import('./views/page-layouts/eLabs')),
      },
      {
        exact: true,
        path: '/app/e-diags',
        component: lazy(() => import('./views/page-layouts/eDiags')),
      },
      {
        exact: true,
        path: '/app/promo-ticket',
        component: lazy(() => import('./views/page-layouts/promoTicket')),
      },
      {
        exact: true,
        path: '/app/sobre-raven',
        component: lazy(() => import('./views/page-layouts/aboutRaven')),
      },
      {
        exact: true,
        path: '/app/dashboard/doctors',
        component: lazy(() => import('./views/dashboard/DashDoctor')),
      },
      {
        exact: true,
        path: '/app/dashboard/pharmacies',
        component: lazy(() => import('./views/dashboard/DashPharmacy')),
      },
      {
        exact: true,
        path: '/app/dashboard/drugs/categories',
        component: lazy(() => import('./views/dashboard/DashDrugCategory')),
      },
      {
        exact: true,
        path: '/app/dashboard/drugs',
        component: lazy(() => import('./views/dashboard/DashDrug')),
      },
      {
        exact: true,
        path: '/app/dashboard/redemptions/status',
        component: lazy(() => import('./views/dashboard/DashRedemptionStatus')),
      },
      {
        exact: true,
        path: '/app/dashboard/redemptions/patient',
        component: lazy(() => import('./views/dashboard/DashRedemptionPatient')),
      },
      {
        exact: true,
        path: '/app/dashboard/invoices',
        component: lazy(() => import('./views/dashboard/DashInvoice')),
      },
      {
        exact: true,
        path: '/app/dashboard/restock',
        component: lazy(() => import('./views/dashboard/DashRestock')),
      },
      {
        exact: true,
        path: '/app/dashboard/employees',
        component: lazy(() => import('./views/dashboard/DashEmployee')),
      },
      {
        exact: true,
        path: '/app/dashboard/patients',
        component: lazy(() => import('./views/dashboard/DashPatient')),
      }
    ],
  },
  {
    path: '*',
    layout: HomeLayout,
    guard: GuestGuard,
    routes: [
      {
        exact: true,
        path: '/',
        component: lazy(() => import('./views/dashboard/Inicio')),
      },
      {
        exact: true,
        path: '/details/nervous-system',
        component: lazy(() =>
          import('./views/dashboard/DashInfoNervousSystem')
        ),
      },
      {
        exact: true,
        path: '/details/feminine-health',
        component: lazy(() => import('./views/dashboard/DashFeminineHealth')),
      },
      {
        exact: true,
        path: '/details/cardiology',
        component: lazy(() => import('./views/dashboard/DashCardiology')),
      },
      {
        exact: true,
        path: '/details/dermatology',
        component: lazy(() => import('./views/dashboard/DashDermatology')),
      },
      {
        exact: true,
        path: '/details/pain',
        component: lazy(() => import('./views/dashboard/DashPain')),
      },
      {
        exact: true,
        path: '/details/respiratory-system',
        component: lazy(() => import('./views/dashboard/DashRepiratorySystem')),
      },
      {
        exact: true,
        path: '/details/gastroenterology',
        component: lazy(() => import('./views/dashboard/DashGastroenterology')),
      },
      {
        exact: true,
        path: '/details/gastroenterology',
        component: lazy(() => import('./views/dashboard/DashGastroenterology')),
      },
      {
        exact: true,
        path: '/details/nutrition-wellness',
        component: lazy(() =>
          import('./views/dashboard/DashNutritionAndWellness')
        ),
      },
      {
        exact: true,
        path: '/cancelar-suscripcion',
        component: lazy(() => import('./views/cancelMembership')),
      },
      {
        exact: true,
        guard: GuestGuard,
        path: '/auth/signin',
        component: lazy(() => import('./views/auth/signin/SignIn1')),
      },
      {
        exact: true,
        path: '/auth/reset',
        component: lazy(() => import('./views/auth/reset-password/ResetPassword4')),
      },
      {
        exact: true,
        guard: GuestGuard,
        path: '/auth/signup',
        component: lazy(() => import('./views/auth/signup/SignUp1')),
      },
    ],
  },
];


export default routes;