import React from 'react';
import { Link } from 'react-router-dom';
import LogoRaven from '../../../../assets/images/logo.svg';
import styled from 'styled-components';

const NavLogo = () => {
    return (
      <React.Fragment>
        <div className="navbar-brand header-logo">
          <Link to="/" className="b-brand">
            <div>
              <Image src={LogoRaven} alt='Logo Raven' />
            </div>
          </Link>
        </div>
      </React.Fragment>
    );
};

export default NavLogo;

const Image = styled.img`
    width: 120px;
`
