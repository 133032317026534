import React from 'react'
import styled from 'styled-components'

const Sidebar = () => {

  return (
    <>
      <Ul>
        <li>
          <a href="/">INICIO</a>
        </li>
        <li>
          <a href="/auth/signin">INGRESAR</a>
        </li>
        <li>
          <a href="/auth/signup">REGISTRARSE</a>
        </li>
      </Ul>
    </>
  );
}

export default Sidebar

const Ul = styled.ul`
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  z-index: 2000;
  padding-left: 0;
  margin-bottom: 0;
  background-color: ${(props) => props.theme.colors.whiteSidebar};
  & li {
    list-style: none;
    padding: 10px;
    & a {
      font-weight: 600;
      color: ${(props) => props.theme.colors.blackLight};
    }
  }
  @media (min-width: 990px) {
    display: none;
  }
`;