/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components'
import LogoRaven from '../../assets/images/logo.svg';

const FooterDashboard = () => {
  return (
    <>
      <Row className='no-gutters'>
        <Container>
          <div>
            <Col xl={12}>
              <figure>
                <img
                  src={LogoRaven}
                  alt="Logo Raven"
                />
                <Figcaption>
                  Generando un impacto duradero en la salud humana por más de
                  130 años
                </Figcaption>
              </figure>
            </Col>
          </div>
          <div>
              <Ul>
                <li>
                  {' '}
                  <p>Más información:</p>{' '}
                </li>
                <li>
                  <a href="#">Consideraciones</a>
                </li>
                <li>
                  <a href="#">Información Legal</a>
                </li>
                <li>
                  <a href="#">Contáctenos</a>
                </li>
                <li>
                  <a href="#">Cancelar suscripción</a>
                </li>
              </Ul>
          </div>
        </Container>
      </Row>
    </>
  );
}

export default FooterDashboard

const Container = styled.div`
  width: 100%;
  border-top: 1px solid ${(props) => props.theme.colors.lightGray};
  padding: 20px;
  & figure {
    margin-bottom: 8px;
    & img {
      width: 90px;
      -webkit-filter: grayscale(1) invert(1); 
      filter: grayscale(1) invert(1);
    }
  }
  & ul {
    padding-left: 15px;
    list-style: none;
    margin-bottom: 0;
    li {
      font-size: 10px;
      margin: 10px 0;
      & p {
        margin-bottom: 0;
        font-size: 10px;
      }
      & a {
        color: #888888;
        margin: 0 5px;
      }
    }
  }
  @media (min-width: 480px) {
    display: grid;
		align-items: center;
    grid-template-columns: 50% 50%;
    grid-template-rows: 100%;
		 & ul {
			 li {
			 margin: 0;
		 }
		}
  }
  @media (min-width: 992px) {
    margin-left: 264px;
  }
`;

const Ul = styled.ul`
  display: flex;
	flex-wrap: wrap;
`;


const Figcaption = styled.figcaption`
	margin-top: 10px;
	font-size: 10px;
`