import useAuth from "./hooks/useAuth";
import menuItemsPatient from "./menu-items-patient";
import menuItemsPharmacy from "./menu-items-pharmacy";
import menuItemsRaven from "./menu-items-raven";

function NavigationBaseOnRole() {
  const { user } = useAuth();
  const role = user.role;
  if (role === "PATIENT") {
    return menuItemsPatient;
  } else if (role === "PHARMACY") {
    return menuItemsPharmacy;
  } else if (role === "SUPPLIER") {
    return menuItemsRaven;
  }
};

export default NavigationBaseOnRole;