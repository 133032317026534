import React from 'react'
import { Button } from 'react-bootstrap';
import styled from 'styled-components'
import Background from '../../assets/images/bg-images/background.jpg'

const Help = () => {
  return (
    <Container style={{backgroundImage: `url(${Background})`}}>
      <div>
        <article>
          <h5>CONTACTANOS</h5>
          <p>Con gusto te atenderemos.</p>
          <p>Llamadas: 4032 6600</p>
          <p>Horarios de atención:</p>
          <p>Lunes a viernes de 7:30 a.m a 4:30 p.m</p>
        </article>
        <div></div>
      </div>
      <ContainerButton>
        <Button onClick={() => {window.location.href = 'mailto:info@laboratorioraven.com'}}>ESCRÍBENOS</Button>
      </ContainerButton>
    </Container>
  );
}

export default Help

const Container = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 100%;
  padding: 64px 16px;
  background-color: #019cde;
  color: #fff;
  & article {
    padding-left: 20px;
    & h5 {
      font-size: 35px;
      color: #fff;
      font-weight: 700;
    }
    & p {
      font-size: 18px;
    }
    & p:nth-child(4) {
      margin-top: 40px;
    }
  }
  & Button {
    width: 220px;
    padding: 10px 15px;
    /*
    color: #222731;
    background-color: #ffa913;
    border-color: #ffa913;
    */
    font-size: 20px;
    border-radius: 26px;
    /*&:hover {
      background-color: #ffa913;
      border-color: #ffa913;
      color: #222731;
    }*/
  }

  /* @media (max-width: 450px) {
    margin-top: 310px;
  }
   */
  @media (max-width: 640px) {
    grid-template-columns: 100%;
    grid-template-rows: 80% 25%;
    & article {
      text-align: center;
    }
  }
`;

const ContainerButton = styled.div`
  justify-self: center;
  align-self: center;

  & button {
    background-color: white;
    color: #072240;
  }
`;