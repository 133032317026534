const theme = {
  colors: {
    orangeYellow: ' #fea82f',
    lightgreen: '#0e7a32',
    purple: '#072240',
    darkGreen: '#034f71',
    blueLight: '#0095d6',
    blackLight: ' #004f71',
    dark: '#343a40',
    white: '#fff',
    whiteSidebar: '#fafafa',
    lightGray: '#ced4da',
    borderBottomColor: '#dee2e6',
    brightGreen: '#1c96be',
    black: '#111',
    beige: '#efefb4'
  },
};


export default theme;

