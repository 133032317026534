const menuItemsPatient = {
  items: [
    {
      id: 'navigation',
      type: 'group',
      children: [
        {
          id: 'inicio',
          title: 'INICIO',
          type: 'item',
          url: '/app/dashboard/default',
        },
        {
          id: 'myAccount',
          title: 'CUENTA',
          type: 'collapse',
          children: [
            {
              id: 'myProfile',
              title: 'MI PERFIL',
              type: 'item',
              url: '/app/dashboard/profile',
              children: [
                {
                  id: 'editProfile',
                  title: 'MI PERFIL',
                  type: 'item',
                  url: '/app/mi-cuenta/editar-perfil',
                },
              ],
            },
            {
              id: 'statusAccount',
              title: 'ESTADO DE CUENTA',
              type: 'item',
              url: '/app/dashboard/estado-de-cuenta',
            },
          ],
        },
        {
          id: 'myShopping',
          title: 'MIS COMPRAS',
          type: 'collapse',
          children: [
            {
              id: 'registerPurchases',
              title: 'REGISTRO DE COMPRAS',
              type: 'item',
              url: '/app/registrar-compras',
            },
            {
              id: 'rejetedInvoices',
              title: 'FACTURAS RECHAZADAS',
              type: 'item',
              url: '/app/invoice-rejected',
            },
          ],
        },
        {
          id: 'myExchanges',
          title: 'MIS CANJES',
          type: 'collapse',
          children: [
            /*{
              id: 'redeemPoints',
              title: 'CANJE DE PRODUCTOS',
              type: 'item',
              url: '/app/canjear-mis-puntos',
            },*/
            {
              id: 'pendingPoints',
              title: 'CANJES PENDIENTES',
              type: 'item',
              url: '/app/canjes-pendientes',
            },
          ],
        },
        {
          id: 'points',
          title: 'LISTA DE PRODUCTOS',
          type: 'item',
          url: '/app/puntos-medicamente-producto',
        },
        {/*
          id: 'eTicket',
          title: 'E-TICKET',
          type: 'item',
          url: '/app/e-ticket',
        */},
        {
          id: 'about',
          title: 'SOBRE RAVEN',
          breadcrumb: 'SOBRE POLÍTICAS DEL PLAN PACIENTE RAVEN',
          type: 'item',
          url: '/app/sobre-raven',
        },
      ],
    },
  ],
};

export default menuItemsPatient;
