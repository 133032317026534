import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, } from 'react-bootstrap';
import Header from '../../components/Header/Header';
import MenuIcon from '../../assets/images/icons/icon-menu.png';
import Help from '../../components/Help/Help';
import Footer from '../../components/Footer/Footer';
import Sidebar from '../../components/Sidebar/Sidebar';


const HomeLayout = ({ children }) => {
  const [showSidebar, setShowSidebar] = useState(false);

  const handleShowSidebar = () => { 
    setShowSidebar(!showSidebar);
  }

  return (
    <React.Fragment>
      <Header />
      <nav>
        {showSidebar ? <Sidebar /> : null}
        {showSidebar ? (
          <Shadow
            onClick={handleShowSidebar}
            style={{ display: showSidebar ? 'block' : 'none' }}
          ></Shadow>
        ) : null}
        <Container>
          <HomeList>
            <li>
              <a href="/">INICIO</a>
            </li>
            <li>
              <a href="/auth/signin">INGRESAR</a>
            </li>
            {/*
            <li>
              <a href="/cancelar-suscripcion">CANCELAR SUSCRIPCIÓN</a>
            </li>
            <li>
              <a href="#">PRODUCTOS PARTICIPANTES</a>
            </li>
            <li>
              <a href="#">CALCULE SUS CANJES</a>
            </li>
            <li>
              <a href="#">CONTÁCTANOS</a>
            </li>
            */}
          </HomeList>
          <ContainerButton>
          <a href="/auth/signup"><Button>REGISTRARSE</Button></a>
          </ContainerButton>
          <ContainerImage>
            <Image onClick={handleShowSidebar} src={MenuIcon} alt="Menu" />
          </ContainerImage>
        </Container>
      </nav>
      <ContainerChildren>{children}</ContainerChildren>
      <Help />
      <Footer />
    </React.Fragment>
  );
}


export default HomeLayout

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.lightGray};

  @media (max-width: 992px) {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
  }
`;

const HomeList = styled.ul`
  display: flex;
  padding-left: 15px;
  list-style: none;
  margin-bottom: 0;
  & li a {
    padding: 8px;
    color: #004f71;
    font-size: 14px;
    font-weight: 600;
  }
  @media (max-width: 992px) {
    display: none;
  }
`
const ContainerButton = styled.div`
  padding: 8px 16px;
  & Button {
    border-radius: 20px;
    font-size: 14px;
  }
  @media (max-width: 992px) {
    display: none;
  }
`

const ContainerImage = styled.div`
  display: flex;
  justify-content: flex-end;
  @media (min-width: 993px) {
    display: none;
  }
`;

const Image = styled.img`
  width: 30px;
  margin-right: 20px;
  margin-bottom: 5px;

  @media (max-width: 992px) {
    margin-top: 8px;
  }
`

const ContainerChildren = styled.div``

const Shadow = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.4;
  z-index: 1000;
  display: none;
`;
